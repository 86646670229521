// import { format } from 'date-fns';
// import { useDateFormat } from '@vueuse/core';

// export function formatDate(date, ptt) {
//     if (!ptt) {
//         ptt = 'dd/MM/yyyy';
//     }
//     return format(new Date(date), ptt);
// }

// export function formatted(date, pattern) {
//     return useDateFormat(date, pattern, { locales: 'en-US' });
// }

export function validName(name) {
    let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
    if (validNamePattern.test(name)) {
        return true;
    }
    return false;
}

export function formatNumber(value) {
    const formatter = new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

export function formatInt(value) {
    const formatter = new Intl.NumberFormat('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(value);
}

export function convertCharToBoolean(value) {
    if (value === 'y') {
        return true;
    } else {
        return false;
    }
}

export function zeroPad(num, places) {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
}

export function cardType(suit) {
    let result = '';
    switch (suit) {
        case 1:
            result = '♠';
            break;
        case 2:
            result = '♥';
            break;
        case 3:
            result = '♣';
            break;
        case 4:
            result = '♦';
            break;
    }
    return result;
}

export function cardNumber(rank) {
    switch (rank) {
        case 1:
            return 'A';
        case 11:
            return 'J';
        case 12:
            return 'Q';
        case 13:
            return 'K';
        default:
            return rank;
    }
}
